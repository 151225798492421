function myLoop(curseur) {
    setTimeout(function () {
        var title = document.getElementById('title');
        if (curseur) {
            title.innerHTML = "Let's code |";
            myLoop(false);
        }
        else {
            title.innerHTML = "Let's code  ";
            myLoop(true);
        }
    }, 750)
};

myLoop(false);

$(window).scroll(function () {
    $(".masthead").css("opacity", 1 - $(window).scrollTop() / 600);
});

AOS.init({
    duration: 1200,
})